var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    attrs: { params: _vm.params, fetch: _vm.fetch },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [
            _c(
              "GridMatchButtons",
              _vm._b(
                {
                  attrs: {
                    item: Object.assign({}, _vm.item, data),
                    scrims: _vm.scrims
                  }
                },
                "GridMatchButtons",
                _vm.$attrs,
                false
              )
            )
          ]
        }
      },
      {
        key: "loading",
        fn: function() {
          return [
            _c(
              "GridMatchButtons",
              _vm._b(
                { attrs: { item: _vm.item, loading: "", scrims: _vm.scrims } },
                "GridMatchButtons",
                _vm.$attrs,
                false
              )
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }