var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabe__cell-actions-wrapper" },
    [
      false
        ? _c(
            "router-link",
            {
              staticClass: "tabe__btn-action tabe__btn-action--stats",
              attrs: { to: _vm.statsPath },
              nativeOn: {
                click: function($event) {
                  return _vm.trackStatsClick($event)
                }
              }
            },
            [_c("span", { staticClass: "tabe__btn-value" }, [_vm._v("Stats")])]
          )
        : _vm._e(),
      !_vm.scrims && _vm.can_access_grid_emea_matches
        ? _c(
            _vm.live ? "router-link" : "span",
            _vm._g(
              {
                tag: "router-link",
                staticClass: "tabe__btn-action tabe__btn-action--map",
                class: { disabled: !_vm.live },
                attrs: { to: _vm.getMapToolPath(_vm.item, false, true) }
              },
              Object.assign(
                {},
                _vm.live
                  ? {
                      "click.native": function(e) {
                        _vm.trackMapClick(e, _vm.item)
                      }
                    }
                  : {}
              )
            ),
            [
              _c(
                "span",
                { staticClass: "tabe__btn-value" },
                [
                  _vm._v(" Live Map "),
                  _vm.loading
                    ? _c("MiniSpinner", { staticClass: "mr-2" })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        _vm.ready ? "router-link" : "span",
        _vm._g(
          {
            tag: "router-link",
            staticClass: "tabe__btn-action tabe__btn-action--map",
            class: { disabled: !_vm.ready },
            attrs: { to: _vm.getMapToolPath(_vm.item, false) }
          },
          Object.assign(
            {},
            _vm.ready
              ? {
                  "click.native": function(e) {
                    _vm.trackMapClick(e, _vm.item)
                  }
                }
              : {}
          )
        ),
        [
          _c(
            "span",
            { staticClass: "tabe__btn-value" },
            [
              _vm._v(" 2D Map "),
              _vm.loading
                ? _c("MiniSpinner", { staticClass: "mr-2" })
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }