var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLazyLoadingController", {
    attrs: {
      fetch: _vm.fetch,
      params: _vm.fetchParams,
      "next-params": _vm.nextParams,
      "has-next": _vm.hasNext
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [
            data && data.length > 0
              ? _c(
                  "GridMatchTable",
                  _vm._g(
                    _vm._b(
                      {
                        attrs: {
                          items: data,
                          "sort-by": "created",
                          "sort-desc": true,
                          "api-key": _vm.apiKey,
                          bookmarkable: false,
                          "highlight-agents": _vm.filter.agent_ids,
                          "highlight-agents-option": _vm.filter.agent_option,
                          scrims: _vm.feed === "SCRIM"
                        }
                      },
                      "GridMatchTable",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  )
                )
              : _c(
                  "div",
                  { staticClass: "text-muted text-center" },
                  [
                    _vm._v(" There are no results matching your filters. "),
                    _c(
                      "router-link",
                      {
                        staticClass: "router-link-active",
                        attrs: { to: { query: { s: _vm.noFiltersSearch } } }
                      },
                      [_vm._v("Try without.")]
                    )
                  ],
                  1
                )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }