var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LoadingController",
    {
      attrs: { loading: _vm.loading, error: _vm.error, lazy: true },
      on: { retry: _vm.retry },
      scopedSlots: _vm._u(
        [
          {
            key: "loading",
            fn: function() {
              return [_vm._t("loading")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.permissionsError
        ? [
            _vm._t("error", function() {
              return [
                _c("PermissionsAlert", {
                  attrs: { message: _vm.permissionsError }
                })
              ]
            })
          ]
        : [
            _vm.data
              ? [
                  _vm._t("default", null, { data: _vm.data }),
                  _vm.hasNext && !_vm.loading
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-primary btn-block",
                            attrs: { disabled: !_vm.hasNext },
                            on: { click: _vm.loadNext }
                          },
                          [_vm._v(" Load more ")]
                        )
                      ]
                    : _vm._e()
                ]
              : _vm._e(),
            !_vm.data && !_vm.loading
              ? [
                  _vm._t("no-data", function() {
                    return [_vm._v(" No data ")]
                  })
                ]
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }